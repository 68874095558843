<template>
  <div :key="JSON.stringify(adressen)">
    <label v-if="label" for="" class="font-bold text-lg">{{ label }}</label>
    <UIInputLabel required>Ophaallocatie</UIInputLabel>
    <AdresOfLuchthaven class="max-w-sm" :disabled="disabled" v-model="adressen[0]" />

    <GoogleTussenstops
      class="my-2 flex flex-col gap-1 max-w-sm"
      v-model="tussenstops"
      :disabled="disabled"
    ></GoogleTussenstops>

    <UIInputLabel required>Bestemming</UIInputLabel>
    <AdresOfLuchthaven class="max-w-sm" :disabled="disabled" v-model="adressen[Math.max(2, adressen.length) - 1]" />
    <button class="mt-2 mx-auto" :disabled="disabled" @click="onReverse">
      <i class="fas fa-sync-alt"></i> Adressen omkeren
    </button>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import clone from 'just-clone'

import UIInputLabel from '@/components/UI/Input/Label.vue'
import AdresOfLuchthaven from './AdresOfLuchthaven.vue'
import GoogleTussenstops from '@/components/Google/Tussenstops.vue'
import { useVModel } from '@vueuse/core'
// import OrderItems from '@/components/UI/Button/OrderItems.vue'

const props = defineProps({
  label: String,
  disabled: Boolean,
  modelValue: {
    type: Array,
    default() { return [null, null] },
  },
})

const emit = defineEmits(['update:modelValue'])

const adressen = useVModel(props, 'modelValue', emit, { deep: true, passive: true })
const tussenstopsFromAdressen = () => clone(adressen.value).slice(1, -1)
const tussenstops = ref(tussenstopsFromAdressen())

watch(tussenstops, () => {
  const array = clone(tussenstops.value)
  array.unshift(adressen.value[0])
  array.push(adressen.value[adressen.value.length - 1])
  adressen.value = array
}, { deep: true })

const onReverse = () => {
  adressen.value = clone(adressen.value).reverse()
  tussenstops.value = tussenstopsFromAdressen()
}
</script>
