import clone from 'just-clone'
// import sharedLib from '@/assets/taxiboeken-shared'
import LUCHTHAVENS from '@/constants/LUCHTHAVENS'
import { dateString, timeString, isDate } from '@/functions/formatDate'
import { useRoute } from 'vue-router'
import { FINANCIAL_JOURNALS, RIT_BEREKENING } from '@/constants/OPTIONS'

const ZAVENTEM = LUCHTHAVENS.find(el => el.name === 'Zaventem')

export default (_defaultValue = null) => {
  const latestBerekeningVersion = RIT_BEREKENING({ bedrijf_thema: 'LUCHTHAVENVERVOERKEVIN' }).find(el => el.isLatest)?.value

  const route = useRoute()
  const heenDatum = isDate(route.query.date) ? new Date(route.query.date) : new Date(Date.now() + 259200000)
  const retourDatum = new Date(heenDatum.getTime() + 86400000)
  const defaultValue = clone(_defaultValue || {})

  const result = clone({
    heenreis: {
      datum: dateString(heenDatum),
      tijd: timeString(heenDatum),
      vlucht: '',
      adressen: [
        null,
        ZAVENTEM.google,
      ],
      personen: 1,
      bagage: 1,
      handbagage: 1,
      kinderstoelen: 0,
      kinderzitjes: 0,
      maxicosis: 0,
      kilometers: 0,
      seconden: 0,
      tolkosten: 0,
      extra_kost: 0,
      // warnings: [],
      ...(defaultValue.heenreis || {}),
    },
    retour: {
      datum: dateString(retourDatum),
      tijd: timeString(retourDatum),
      vlucht: '',
      adressen: [
        ZAVENTEM.google,
        null,
      ],
      personen: 1,
      bagage: 1,
      handbagage: 1,
      kinderstoelen: 0,
      kinderzitjes: 0,
      maxicosis: 0,
      kilometers: 0,
      seconden: 0,
      tolkosten: 0,
      extra_kost: 0,
      // warnings: [],
      ...(defaultValue.retour || {}),
    },
    facturatie: {
      actief: false,
      name: '',
      second_name: '',
      is_particulier: false,
      BTW: '',
      adres: '',
      ...(defaultValue.facturatie || {}),
    },
    klant: {
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      language: 'nl',
      ...(defaultValue.klant || {}),
    },
    reiziger: {
      actief: false,
      language: 'nl',
      ...(defaultValue.reiziger || {}),
    },

    berekening: latestBerekeningVersion,

    bedrijf_thema: 'LUCHTHAVENVERVOERKEVIN',
    promotie_code: defaultValue.promotie_code || '',
    btw_percentage: 6,
    korting: Math.max(defaultValue.korting || 0, 0),
    korting_promotie: Math.max(defaultValue.korting_promotie || 0, 0),
    extra_kost: 0,
    prijs_verhoging: 0,
    prijs: 0,
    passagier_saldo: 0,
    passagier_saldo_factureren: false,
    facturatie_referentie: '',
    facturatie_journal_id: FINANCIAL_JOURNALS[0].value,

    is_retour: false,
    is_factuur_nodig: false,
    is_prive_vervoer: false,
    is_luxe_vervoer: false,
    is_wakeup_call: false,
    is_last_minute: false,
    is_annulatie_verzekering: false,

    betaalmethode: 'ONLINE',
    bron: 'PHONE',
    opmerking: '',
    extra_vraag: '',
    persona: null,
    planning_optie_heen: '',
    planning_optie_retour: '',
    check_nieuwsbrief: true,
    check_voorwaarden: true,
  })

  delete defaultValue.heenreis
  delete defaultValue.retour
  delete defaultValue.facturatie
  delete defaultValue.klant
  delete defaultValue.reiziger

  return Object.assign(result, defaultValue)
}
