import { computed } from 'vue'

const feestdagen = [
  '12-24',
  '12-25',
  '12-31',
  '01-01',
]

export default ({ form }) => {
  const heenMMDD = computed(() => String(form.value.heenreis.datum).split('-').slice(1).join('-'))
  const retourMMDD = computed(() => !form.value.is_retour ? '' : String(form.value.retour.datum).split('-').slice(1).join('-'))

  const isFeestdagHeen = computed(() => feestdagen.includes(form.value.heenreis.datum) || feestdagen.includes(heenMMDD.value))
  const isFeestdagRetour = computed(() => {
    if (!form.value.is_retour) return false
    return feestdagen.includes(form.value.retour.datum) || feestdagen.includes(retourMMDD.value)
  })

  return { isFeestdagHeen, isFeestdagRetour }
}
