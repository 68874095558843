<template>
  <div class="google-directions my-1 text-left px-4 py-1" v-show="loading">
    <slot><small>Route berekenening {{ id }} ...</small></slot>
  </div>
</template>

<script setup>
import { computed, ref, watch, onMounted, defineProps, defineEmits, onBeforeUnmount } from 'vue'
import { compressToBase64, decompressFromBase64 } from 'lz-string'
import clone from 'just-clone'
import md5 from 'md5'

import useGoogleMaps from '@/hooks/useGoogleMaps'


// @TODO: Nieuwe berekening typescript util gebruiken na herimplementatie.
const getGoogleMapsDirectionsRouteResponse = (
  directionRoutes,
  opts = { basedOn: 'DISTANCE' },
) => {
  let route
  let meters = 0
  let time = 0

  directionRoutes.forEach((directionRoute) => {
    const routeMeters = directionRoute.legs.reduce((sum, leg) => {
      return sum + (leg.distance?.value || 0)
    }, 0)

    const routeTime = directionRoute.legs.reduce((sum, leg) => {
      return sum + (leg.duration?.value || 0)
    }, 0)

    if (
      !route ||
      (opts.basedOn === 'DISTANCE' && (meters === 0 || meters >= routeMeters)) ||
      (opts.basedOn === 'TIME' && (time === 0 || time >= routeTime))
    ) {
      route = directionRoute
      time = routeTime
      meters = routeMeters
    }
  })

  return {
    time, // In seconds
    meters,
    route,
  }
}



const getCacheKey = (value) => {
  const tmp = typeof value === 'object' ? JSON.stringify(value) : String(value)
  return `Directions-${process.env.VUE_APP_DIRECTIONS_STORAGE_VERSION}-${md5(tmp.toLowerCase())}`
}

const googleInputToDirection = (googleInputResult) => {
  if (!googleInputResult) return ''
  if (typeof googleInputResult === 'string') {
    return googleInputResult
  }
  if (googleInputResult.is_luchthaven && googleInputResult.adres) {
    return googleInputResult.adres
  }
  // if (googleInputResult.place_id || googleInputResult.placeId) {
  //   return { placeId: googleInputResult.place_id || googleInputResult.placeId }
  // }
  if (googleInputResult.location) {
    return { location: googleInputResult.location }
  }
  if (googleInputResult.geocode || googleInputResult.coords) {
    const coords = googleInputResult.geocode || googleInputResult.coords
    return { lat: coords.lat, lng: coords.lng }
  }
  if (googleInputResult.lng && googleInputResult.lat) {
    return { lat: googleInputResult.lat, lng: googleInputResult.lng }
  }
  return googleInputResult.adres || googleInputResult
}

const waypointMapper = (point) => {
  if (typeof point === 'string') {
    return {
      location: point,
      stopover: true,
    }
  }
  return {
    location: googleInputToDirection(point),
    stopover: point.stopover || point.stopover === false ? point.stopover : true,
  }
}

const props = defineProps({
  // GoogleInput.vue array of those results
  adressen: {
    type: Array,
    required: true,
    default() {
      return []
    },
  },
  // Unique ID
  id: {
    type: String,
    required: true,
  },
  berekening: [String, Number],
  debug: Boolean,
  loadingTimeout: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['error', 'directions'])

const promises = ref({})
const loading = ref(false)
const timeout = ref(null)

const directions = computed(() => {
  const adressen = props.adressen?.filter(el => !!el) || []
  if (adressen.length < 2) {
    return {}
  }
  const obj = {
    origin: adressen[0] || {},
    destination: adressen.slice(-1)[0] || {},
    waypoints: adressen.slice(1, -1) || [],
    fixed: 'destination',
  }
  if (obj.waypoints.length || (obj.origin && obj.origin.is_luchthaven)) {
    obj.fixed = 'origin'
  }
  return obj
})

const cacheKey = computed(() => getCacheKey(clone(props.adressen).filter(el => el?.adres)))

const directionRequest = computed(() => {
  const DIRECTIONS = directions.value
  const result = {
    origin: googleInputToDirection(DIRECTIONS.origin),
    destination: googleInputToDirection(DIRECTIONS.destination),
    travelMode: 'DRIVING',
    drivingOptions: {
      departureTime: new Date(2533770000000),
      trafficModel: 'optimistic',
    },
    provideRouteAlternatives: true,
    optimizeWaypoints: false,
    avoidHighways: false,
    avoidTolls: false,
    waypoints: [],
  }
  const hasWaypoints = DIRECTIONS.waypoints && DIRECTIONS.waypoints.length > 0
  if (hasWaypoints) {
    result.waypoints = DIRECTIONS.waypoints.slice(0).map(waypointMapper)
  }
  return Object.freeze(result)
})

// eslint-disable-next-line
const getExtraFromResponse = ({ result, route, response }) => {
  return Object.freeze({
    id: props.id,
    distance: result.meters,
    duration: result.time,
    warnings: route.legs[0].warnings || [],
    is_cache: false,
  })
}

const setWindowCacheCalculation = (calculation) => {
  const obj = clone(calculation)
  delete obj.response
  delete obj.result
  delete obj.route
  const data = compressToBase64(JSON.stringify(obj))
  if (window.localStorage) window.localStorage.setItem(cacheKey.value, data)
  window.googleCache[cacheKey.value] = data
}

const getDirections = async () => {
  console.warn('[Direction Request]', 'getDirections called ..', directions.value.origin === directions.value.destination, directions.value.waypoints?.length > 0)

  if (directions.value.origin === directions.value.destination && !directions.value.waypoints?.length) {
    const RESULT_NUL = Object.freeze({ id: props.id, distance: 0, duration: 0, warnings: [], is_cache: true })
    console.log('Calculation:', 'RESULT_NUL', RESULT_NUL)
    emit('directions', RESULT_NUL)
    return RESULT_NUL
  }

  window.googleCache = window.googleCache || {}
  let cacheString = window.googleCache[cacheKey.value]
  if (!cacheString && window.localStorage) cacheString = window.localStorage.getItem(cacheKey.value)
  if (cacheString) {
    const cachingResult = JSON.parse(decompressFromBase64(cacheString))
    cachingResult.id = props.id
    cachingResult.is_cache = true
    console.log('Calculation:', 'CACHE', cachingResult)
    emit('directions', cachingResult)
    return cachingResult
  }

  if (promises.value[cacheKey.value]) {
    return promises.value[cacheKey.value].then((res) => {
      return res
    })
  }

  const promise = new Promise((resolve) => {
    window.directionsService.route(directionRequest.value, (googleResponse, googleStatus) => {
      const STATUS_NOT_OK = googleStatus !== 'OK'
      if (STATUS_NOT_OK) {
        console.warn('[Direction Request NOT OK]', googleResponse, googleStatus)
        emit('error', {
          result: null,
          route: null,
          response: googleResponse,
          mappedResponse: null,
        })
        delete promises.value[cacheKey.value]
        return resolve(null)
      }
      if (googleResponse.routes && googleResponse.routes.length > 0) {
        const result = getGoogleMapsDirectionsRouteResponse(googleResponse.routes)
        result.shortest = result.route
        if (result.shortest) {
          const data = {
            result,
            route: result.shortest,
            response: googleResponse,
          }
          const calculation = getExtraFromResponse(data)
          // calculation = Object.assign({}, calculation, data)
          console.log('Calculation:', 'GOOGLE', calculation)
          emit('directions', calculation)
          delete promises.value[cacheKey.value]
          return resolve(calculation)
        }
      }

      delete promises.value[cacheKey.value]
      return resolve(null)
    })
  })

  promises.value[cacheKey.value] = promise
  return promise
}

const calculate = async () => {
  const google = await useGoogleMaps()
  if (!google) {
    console.log('[Directions errors]', 'Google could not be loaded')
    return
  }

  window.directionsService = window.directionsService || new google.maps.DirectionsService()

  clearTimeout(timeout.value)
  loading.value = true

  try {
    const calculation = await getDirections()
    if (calculation && calculation.is_cache !== true) {
      setWindowCacheCalculation(calculation)
    }
  } catch (e) {
    console.error(e)
    await getDirections()
  } finally {
    clearTimeout(timeout.value)
    timeout.value = setTimeout(() => (loading.value = false), props.loadingTimeout)
  }
}

onMounted(() => {
  calculate()
})

onBeforeUnmount(() => {
  clearTimeout(timeout.value)
})

watch(directionRequest, (val, oldval) => {
  if (JSON.stringify(val) === JSON.stringify(oldval)) return
  calculate()
})

// emitMap(route, result, response = false) {
//   const addresses = route.legs
//   .reduce((prev, curr) => {
//     prev.push(curr.end_address);
//     return prev;
//   }, [route.legs[0].start_address])
//   .filter((el, index, self) => {
//     return self.indexOf(el) === index;
//   });

//   this.$set(this.mapinfo, 'ophaal', addresses[0]);
//   this.$set(this.mapinfo, 'waypoints', addresses.slice(1, addresses.length - 1));
//   this.$set(this.mapinfo, 'bestemming', addresses[addresses.length - 1]);
//   this.$set(this.mapinfo, 'distance', result.meters);
//   this.$set(this.mapinfo, 'duration', result.time);
//   this.$set(this.mapinfo, 'warnings', route.warnings || route.legs[0].warnings || []);
//   if (response && response.geocoded_waypoints) {
//     const mappedAdressen = addresses
//       .slice(0)
//       .map((adres, index) => {
//         const obj = response.geocoded_waypoints[index] || {};
//         return {
//           place_id: obj.place_id,
//           adres,
//           is_luchthaven: !!(this.findLuchthaven(adres) || (obj.types && obj.types.indexOf('airport') > -1)),
//         };
//       });
//     this.$set(this.mapinfo, 'adressen', mappedAdressen);
//     this.$set(this.mapinfo,
//       'is_ophaal_luchthaven',
//       mappedAdressen[0].is_luchthaven
//         || ((response.geocoded_waypoints[0].types || []).indexOf('airport') > -1),
//     );
//     this.$set(this.mapinfo,
//       'is_bestemming_luchthaven',
//       mappedAdressen[mappedAdressen.length - 1].is_luchthaven
//         || ((response.geocoded_waypoints[response.geocoded_waypoints.length - 1].types || []).indexOf('airport') > -1),
//     );
//   }
//   if (this.map && route.bounds) this.mapEl.fitBounds(route.bounds);
//   this.$nextTick(() => {
//     try {
//       window.googleCache[this.cache] = decompressFromBase64(JSON.stringify(this.mapinfo));
//     } catch (err) {
//       console.error('Directions GoogleCache', err);
//     }
//     this.minimumZoom(this.mapEl);
//     this.$emit('mapinfo', this.mapinfo);
//     this.show = true;
//   });
// },
</script>
