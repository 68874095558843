<template>
  <small>
    <div><b class="text-red-500">Opgelet!</b> Dit is een feestdag.</div>
    <div>Minimaal <b>30 euro per enkele rit</b> invullen onder 'extra kost'</div>
  </small>
</template>

<script setup>

</script>
