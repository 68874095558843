<template>
  <UIInputLabel v-if="label" :focussed="focussed" :id="id" class="mt-0.5 -mb-1">
    {{ label }}
  </UIInputLabel>
  <div class="flex-row items-center" :class="{ 'mb-0.5': !label }" v-bind="$attrs">
    <button v-if="!hideButtons" class="input w-8" :disabled="innerValue <= minInner" @click="increment(-1)">
      -
    </button>
    <select v-model="innerValue" class="mx-0.5" :id="id" @focus="focussed = true" @blur="focussed = false">
      <option v-for="n in maxInner" :key="n" :value="n + minInner - 1">{{ n + minInner - 1 }}</option>
    </select>
    <button v-if="!hideButtons" class="input w-8" :disabled="innerValue >= maxInner" @click="increment(1)">
      +
    </button>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import UIInputLabel from '@/components/UI/Input/Label.vue'
import useMinMaxValues, { PROPS as PropsMinMax } from '@/hooks/useMinMaxValues'

const props = defineProps({
  ...PropsMinMax,
  id: {
    type: [Number, String],
    default: uuidv4,
  },
  label: String,
  hideButtons: Boolean,
})

const focussed = ref(false)

const emit = defineEmits(['update:modelValue'])
const { minInner, maxInner, innerValue, increment } = useMinMaxValues(props, emit)
</script>
