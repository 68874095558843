<template>
  <div class="grid 2xl:grid-cols-2 gap-4 max-w-6xl xl:max-w-full mx-auto" :key="componentData.isNieuw">
    <PrijsFloating v-if="berekeningPrijs > 0 || (berekeningPrijs == 0 && form.promotie_code)">
      <span>{{ berekeningPrijs }}</span>
    </PrijsFloating>
    <RitAlertFloating class="top-4" v-if="toonPriveVerplichtMelding">
      <p class="block text-sm" >Normaal verplicht privé vervoer!</p>
    </RitAlertFloating>
    <div v-for="deel in ['heenreis', 'retour']" :key="deel">
      <div class="inline-flex w-full justify-between">
        <h2>{{ deel === 'retour' ? 'Retour' : 'Heenreis' }}</h2>
        <div v-if="deel === 'heenreis' && !componentData.adressenWijzigen" class="flex bg-gray-100 rounded-t px-2">
          <UIInputCheckbox label="Adressen bewerken?" v-model="componentData.adressenWijzigen" />
        </div>
      </div>

      <div class="card shadow lg:grid lg:grid-cols-2 lg:gap-4">
        <div class="flex flex-col gap-y-2" :class="{ 'bg-gray-100 -mx-2 px-2 -mt-2 pt-2 lg:-m-2 lg:p-2 cursor-not-allowed': !componentData.adressenWijzigen }">
          <UIInputCheckbox :disabled="!componentData.adressenWijzigen" v-if="deel === 'retour'" label="Retour ook boeken?" v-model="form.is_retour" />
          <UIInputCheckbox :disabled="!componentData.adressenWijzigen" v-if="deel === 'retour' && form.is_retour" label="Retour verschillende adressen?" v-model="componentData.retourVerschillendeAdressen" />
          <GoogleAddressenLijst :disabled="!componentData.adressenWijzigen" v-if="deel === 'heenreis' || (form.is_retour && componentData.retourVerschillendeAdressen)" v-model="form[deel].adressen" />
          <p class="italic font-semibold text-sm text-gray-600 flex flex-col gap-1 pt-2" v-if="form[deel].seconden > 0 && (deel !== 'retour' || (form.is_retour && componentData.retourVerschillendeAdressen))">
            <span class="block">Geschatte reistijd: +- {{ secondsToHMSWritten(form[deel].seconden) }}</span>
            <span class="block">Kilometers: {{ formatDecimalDefault(form[deel].kilometers) }} km</span>
            <span class="block">Google adressen: {{ (form[deel].adressen || [])?.filter(el => !!el?.adres)?.length }}</span>
          </p>
          <GoogleDirections
            v-if="componentData.adressenWijzigen && (deel === 'heenreis' || (form.is_retour && componentData.retourVerschillendeAdressen)) && form[deel].adressen?.filter(el => !!el).length >= 2"
            :key="`${deel}-${form[deel].adressen?.filter(el => el?.adres)?.length}`"
            :id="deel"
            :adressen="form[deel].adressen"
            @directions="onDirections($event)"
          >
            <RitBerekeningLoadingMask></RitBerekeningLoadingMask>
          </GoogleDirections>
        </div>

        <div v-if="deel !== 'retour' || form.is_retour" class="flex flex-col gap-y-2">
          <div class="flex flex-col items-start justify-center">
            <InputDatePicker label="Datum en tijd" class="text-left" timepicker v-model:date="form[deel].datum" v-model:time="form[deel].tijd" />
            <small class="font-bold italic text-orange-400" v-if="form.is_last_minute && deel !== 'retour'">Last minute staat aangevinkt</small>
            <RitAlertFeestdag v-if="deel !== 'retour' ? isFeestdagHeen : isFeestdagRetour" />
          </div>
          <div class="grid sm:grid-cols-2 gap-2">
            <div class="flex flex-col gap-2">
              <div class="flex flex-col relative">
                <InputNumber class="flex" label="Personen" :min="1" :max="50" v-model="form[deel].personen" />
              </div>
              <InputNumber class="flex" label="Bagage" :min="0" :max="50" v-model="form[deel].bagage" />
              <InputNumber class="flex" label="Handbagage" :min="0" :max="50" v-model="form[deel].handbagage" />
            </div>
            <div class="flex flex-col gap-2">
              <InputNumber class="flex" label="Kinderstoelen" :min="0" :max="50" v-model="form[deel].kinderstoelen" />
              <InputNumber class="flex" label="Kinderzitjes" :min="0" :max="50" v-model="form[deel].kinderzitjes" />
              <InputNumber class="flex" label="Maxicosis" :min="0" :max="50" v-model="form[deel].maxicosis" />
            </div>
          </div>
          <UIInput label="Vluchtnummer" type="text" class="max-w-md" placeholder="Vluchtnummer ..." v-model="form[deel].vlucht" />
        </div>
      </div>
    </div>

    <div>
      <h2>Reservatie opties</h2>
      <div class="card shadow lg:grid lg:grid-cols-2 lg:gap-4">
        <div class="flex flex-col gap-2">
          <UISelectFixed type="RIT_AANVRAAG" v-model="form.bron" :disabled="form.bron === 'WEBSITE'" />
          <UISelectFixed type="TYPE_VERVOER" v-model="componentData.typeVervoer" />
          <UISelectFixed type="BETAALMETHODE" v-model="form.betaalmethode" />
          <UIInputCheckbox label="Te ontvangen door chauffeur?" v-model="form.is_te_ontvangen" />

          <div class="flex flex-row gap-2 items-end">
            <UIInput label="Voucher" :disabled="!!form.promotie_code" v-model="componentData.promotie_code" class="w-32" placeholder="Vouchercode ..." />
            <button class="btn success" v-if="componentData.promotie_code && form.promotie_code !== componentData.promotie_code" @click="handleVoucherToepassen">Toepassen</button>
            <button class="btn error" v-else-if="!!form.promotie_code" @click="handleClickClearVoucher">Clear</button>
          </div>
          <UISelectFixed class="pb-2 text-xs" type="RIT_BEREKENING" :key="form.bedrijf_thema" :options-params="{ bedrijf_thema: form.bedrijf_thema }" v-model="form.berekening" />

          <UIInputCheckbox label="Last minute?" v-model="form.is_last_minute" />
          <UIInputCheckbox v-if="!isOfferte" label="Wake-up call?" v-model="form.is_wakeup_call" />
          <UIInputCheckbox v-if="!isOfferte" disabled label="Annulatie verzekering?" v-model="form.is_annulatie_verzekering" />
          <UISelectFixed type="BEDRIJVEN_THEMA" v-model="form.bedrijf_thema" :disabled="form.bron === 'WEBSITE'" />
        </div>
        <div class="flex flex-col gap-4">
          <h3>Factuur gegevens</h3>
          <div class="grid gap-1 scale-90" :class="{ 'grid-cols-2 whitespace-nowrap': form.facturatie.actief }">
            <UIInputCheckbox label="Factuur nodig?" v-model="form.is_factuur_nodig" />
            <UIInputCheckbox v-if="form.is_factuur_nodig" label="Factuur gegevens?" v-model="form.facturatie.actief" />
          </div>
          <div v-show="form.facturatie.actief" class="flex flex-col gap-2">
            <FormFacturatie v-if="form.facturatie.actief" v-model="form.facturatie" class="gap-2"/>
            <UISelectFixed
              type="BTW_PERCENT"
              class="w-18"
              label="BTW %"
              :disabled="form.facturatie.actief && form.facturatie.is_particulier"
              v-model="form.btw_percentage"
              :placeholder="`BTW ...`"
            />
          </div>
          <div v-show="form.is_factuur_nodig" class="flex flex-col gap-2">
            <UISelectFixed v-model="form.facturatie_journal_id" type="FINANCIAL_JOURNALS" />
            <UIInput type="text" label="Klant factuur referentie" hint="Eigen referentie van klant voor op factuur?" v-model="form.facturatie_referentie" />
            <UIInput type="number" label="Passagier saldo" hint="Bij ritten van Mutas bijvoorbeeld" v-model="form.passagier_saldo" />
            <UIInputCheckbox label="Passagier saldo factureren?" v-model="form.passagier_saldo_factureren" />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h2>Persoon gegevens</h2>
      <div class="card shadow lg:grid lg:grid-cols-2 lg:gap-4">
        <div class="flex flex-col gap-2">
          <h3>Klant gegevens</h3>
          <FormKlant v-model="form.klant" />
          <UISelectFixed type="PERSONA" all v-model="form.persona" />
        </div>
        <div class="flex flex-col gap-2">
          <h3>Reiziger gegevens</h3>
          <UIInputCheckbox label="Andere reiziger informatie?" v-model="form.reiziger.actief" />
          <FormKlant v-if="form.reiziger.actief" v-model="form.reiziger" />
        </div>
      </div>
    </div>

    <div>
      <h2>Opmerking en memo</h2>
      <div class="card shadow lg:grid lg:grid-cols-2 lg:gap-4">
        <UIInput textarea v-model="form.opmerking" label="Opmerking" class="w-full" placeholder="Opmerking ..." />
        <UIInput textarea v-model="form.extra_vraag" label="Extra vraag" class="w-full" placeholder="Extra vraag ..." />
        <UIInput textarea v-model="form.planning_optie_heen" label="Memo heen (intern)" class="w-full" placeholder="Memo heen (intern) ..." />
        <UIInput v-if="form.is_retour" textarea v-model="form.planning_optie_retour" label="Memo retour (intern)" class="w-full" placeholder="Memo retour (intern) ..." />
      </div>
    </div>

    <div>
      <h2>Prijs en bevestigen</h2>
      <div class="card shadow">
        <div v-if="berekeningPrijs < 0">
          <small class="block">Rit prijs niet berekenbaar.</small>
          <button v-if="!form.promotie_code && form.korting > 0" class="btn info" @click="form.korting = 0">Korting verwijderen</button>
        </div>
        <div v-else>
          <div class="lg:grid lg:grid-cols-4 lg:gap-2 mb-2">
            <UIInput label="Kilometers heenreis" disabled class="lg:w-42" v-model="form.heenreis.kilometers" />
            <UIInput v-show="form.is_retour" label="Kilometers retour" disabled class="lg:w-42" v-model="form.retour.kilometers" />
            <UIInput label="Tolkosten heenreis" class="lg:w-42" :class="inputHighlightClasses(form.heenreis.tolkosten > 0)" v-model="form.heenreis.tolkosten" />
            <UIInput v-show="form.is_retour" label="Tolkosten retour" class="lg:w-42" :class="inputHighlightClasses(form.retour.tolkosten > 0)" :disabled="!form.is_retour" v-model="form.retour.tolkosten" />
          </div>
          <div class="lg:grid lg:grid-cols-4 lg:gap-2">
            <div class="flex flex-col gap-1 flex-1">
              <UIInput label="Korting" class="lg:w-42" :class="inputHighlightClasses(form.korting > 0)" placeholder="Korting ..." v-model="form.korting" />
              <UIInput v-if="form.promotie_code && form.korting_promotie > 0" label="Korting Promotie" class="lg:w-42" disabled :class="inputHighlightClasses(form.korting_promotie > 0)" placeholder="Promotie Korting ..." v-model="form.korting_promotie" />
              <small class="italic" v-if="form.promotie_code && form.korting_promotie > 0">{{ form.promotie_code }}</small>
            </div>
            <UIInput label="Extra kost" info="'Extra kost' komt <b>NIET</b> bij de omzet van de chauffeurs bij" class="lg:w-42 flex-1" :class="inputHighlightClasses(form.extra_kost > 0)" placeholder="Extra kost ..." v-model="form.extra_kost" />
            <UIInput label="Prijs verhoging" info="'Prijs verhoging' komt <b>WEL</b> bij de omzet van de chauffeurs bij" class="lg:w-42 flex-1" :class="inputHighlightClasses(form.prijs_verhoging > 0)"  placeholder="Prijs verhoging ..." v-model="form.prijs_verhoging" />
            <UIInput label="Prijs" disabled class="lg:w-42 flex-1" :modelValue="berekeningPrijs" />
          </div>
          <RitBerekeningPrijsVerschilt v-if="!componentData.isNieuw" class="mt-2" :prijs="berekeningPrijs" :oude-prijs="originalForm.prijs" />
          <div class="flex flex-col sm:flex-row gap-4 mt-4 items-center sm:items-end justify-center">
            <button type="submit" :disabled="!form.klant.lastname || !form.klant.email" class="btn success" @click.prevent.stop="onSubmit(true)">{{ isOfferte ? 'Opslaan en mailen' : 'Met bevestiging' }} {{ dupliceren ? 'DUPLICEREN' : '' }}</button>
            <button type="submit" :disabled="!form.klant.lastname" class="btn error" @click.prevent.stop="onSubmit(false)">{{ isOfferte ? 'Opslaan zonder mail' : 'Zonder bevestiging' }} {{ dupliceren ? 'DUPLICEREN' : '' }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import clone from 'just-clone'
import { computed, onMounted, ref, watch, nextTick } from 'vue'

import computedBerekening from './Rit/computedBerekening'
import generateRitFormData from './Rit/generateRitFormData'
import setRetourAdressenFromHeen from './Rit/setRetourAdressenFromHeen'
import syncForm from './Rit/syncForm'
import isAdressenVerschillend from './Rit/isAdressenVerschillend'
import loadInPropsData from './Rit/loadInPropsData'
import syncQueryData from './Rit/syncQueryData'
import syncPromotieCode from './Rit/syncPromotieCode'
import syncLastMinute from './Rit/syncLastMinute'
// import syncTolkosten from './Rit/syncTolkosten'
import syncPriveVerplichtMelding from './Rit/syncPriveVerplichtMelding'

import GoogleAddressenLijst from '@/components/Google/AdressenLijst.vue'
import GoogleDirections from '@/components/Google/Directions.vue'
import UIInput from '@/components/UI/Input/Input.vue'
import UIInputCheckbox from '@/components/UI/Input/Checkbox.vue'
import InputNumber from '@/components/UI/Input/Number'
import InputDatePicker from '@/components/UI/Input/DatePicker'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'
import FormKlant from '@/components/Form/Klant.vue'
import FormFacturatie from '@/components/Form/Facturatie.vue'
import PrijsFloating from '@/components/UI/Labels/PrijsFloating.vue'
import RitAlertFloating from '@/components/UI/Labels/RitAlertFloating.vue'
import RitAlertFeestdag from '@/components/UI/Labels/RitAlertFeestdag.vue'
import RitBerekeningLoadingMask from '@/components/UI/Modal/RitBerekeningLoadingMask'
import RitBerekeningPrijsVerschilt from '@/components/UI/Labels/RitBerekeningPrijsVerschilt.vue'
import { secondsToHMSWritten } from '@/functions/formatDate'
import { formatDecimalDefault } from '@/functions/formatNumber'
import parseFeestdagAlert from './Rit/parseFeestdagAlert'

const props = defineProps({
  modelValue: Object,
  klant: Object,
  facturatie: Object,
  isOfferte: Boolean,
  dupliceren: Boolean,
})

const emit = defineEmits(['submit', 'update:modelValue', 'datum-heenreis-aangepast', 'datum-retour-aangepast'])

let originalForm = ref(generateRitFormData(props.modelValue))
let form = ref(generateRitFormData(props.modelValue))

if (props.isOfferte) {
  form.value.heenreis.tijd = '00:00'
  form.value.retour.tijd = '00:00'
}

onMounted(() => {
  watch(form, (val) => emit('update:modelValue', val), { deep: true })
})

const componentData = ref({
  promotie_code: null,
  isNieuw: true,
  adressenWijzigen: true,
  retourVerschillendeAdressen: false,
  typeVervoer: form.value?.is_luxe_vervoer ? 'luxe' : (form.value?.is_prive_vervoer ? 'prive' : 'gedeeld'),
})

// const reRenderKey = computed(() => [form.value.is_retour].join(''))
const typeVervoerComputed = computed(() => componentData.value.typeVervoer)
const retourVerschillendeAdressenComputed = computed(() => componentData.value.retourVerschillendeAdressen && (
  isAdressenVerschillend(form.value.heenreis.adressen, form.value.retour.adressen)
))

loadInPropsData({ props, form, originalForm, componentData })
syncForm({ form, componentData, typeVervoerComputed, retourVerschillendeAdressenComputed })
syncQueryData({ form })
// syncTolkosten(form)
syncLastMinute({ componentData, form })
const { isFeestdagHeen, isFeestdagRetour } = parseFeestdagAlert({ form })
const toonPriveVerplichtMelding = syncPriveVerplichtMelding({ form })

const computedBerekeningRef = computedBerekening(form, typeVervoerComputed)
const berekeningPrijs = computed(() => {
  if (!componentData.value.isNieuw && !componentData.value.adressenWijzigen && !form.value.touched) {
    return originalForm.value.prijs
  }
  return computedBerekeningRef.value?.prijs || 0
})

onMounted(() => {
  watch(form, () => { form.value.touched = true }, { deep: true })
  watch(() => form.value.heenreis.datum, (date) => emit('datum-heenreis-aangepast', date))
  watch(() => form.value.retour.datum, (date) => emit('datum-retour-aangepast', date))
})

const inputHighlightClasses = computed(() => (shouldUseClass = true) => ({
  'border-2 border-yellow-300 bg-yellow-300 rounded -mt-0.5': shouldUseClass,
}))


const onDirections = ({ id, distance, duration }) => {
  console.log('onDirections', { id, distance, duration, test: componentData.value })

  if (!componentData.value.isNieuw && !componentData.value.adressenWijzigen) {
    return
  }

  if (form.value[id]) {
    form.value[id].seconden = Math.max(0, duration || 0)
    form.value[id].kilometers = Math.max(0, (distance || 0) / 1000)
    form.value[id].tolkosten = form.value[id].adressen.reduce((total, a) => total + (a?.tolkosten || 0), 0)
  }

  if (id === 'heenreis' && !componentData.value.retourVerschillendeAdressen) {
    setRetourAdressenFromHeen(form)
    onDirections({ id: 'retour', distance, duration })
  }
}

const {
  handleVoucherToepassen,
  handleClearVoucher,
} = syncPromotieCode({ componentData, originalForm, form, computedBerekeningRef, typeVervoerComputed })

const handleClickClearVoucher = () => {
  handleClearVoucher()
  if (!componentData.value.isNieuw) {
    nextTick(() => onSubmit(false))
  }
}

const onSubmit = (mail = false) => {
  const submitData = clone({
    ...form.value,
    prijs: berekeningPrijs.value,
    mail: !!mail,
  })

  const a1 = submitData.heenreis.adressen
  const a2 = submitData.retour.adressen
  if (!a1[0] || !a1[a1.length - 1]) return alert('Gelieve adressen heenrit na te kijken.')
  if (submitData.is_retour) {
    if (!a2[0] || !a2[a2.length - 1]) return alert('Gelieve adressen retour na te kijken.')
  }

  if (!retourVerschillendeAdressenComputed.value) {
    setRetourAdressenFromHeen(form)
  }

  emit('submit', submitData)
}
</script>
