<template>
  <div class="prijs-berekening-modal fixed bg-blue-100 w-full left-0 right-0 text-blue-500 border-b border-blue-200 flex justify-center items-center text-lg lg:text-xl">
    Prijs berekening ...
  </div>
</template>

<style scoped>
.prijs-berekening-modal {
  top: 64px;
}
</style>
