import { watch } from 'vue'
// import sharedLib from '@/assets/taxiboeken-shared'

import { RIT_BEREKENING } from '@/constants/OPTIONS'

import { isLastMinute } from '@/assets/berekening/lib.min'

// import generateRitFormData from './generateRitFormData'
import setRetourAdressenFromHeen from './setRetourAdressenFromHeen'
import syncPersonenBagage from './syncPersonenBagage'

// All properties are refs
export default ({ form, typeVervoerComputed, componentData, retourVerschillendeAdressenComputed }) => {
  watch(() => form.value.heenreis.datum, () => {
    if (componentData.isNieuw) {
      form.value.is_last_minute = isLastMinute(form.value.heenreis.datum)
    }
  })

  let oud_btw_percentage = 6
  watch([() => form.value.facturatie.actief, () => form.value.facturatie.is_particulier], () => {
    const facturatie = form.value.facturatie
    const nieuw_btw_percentage = facturatie.actief && facturatie.is_particulier ? 0 : oud_btw_percentage
    oud_btw_percentage = Number(form.value.btw_percentage)
    form.value.btw_percentage = nieuw_btw_percentage
  })

  watch(typeVervoerComputed, (type) => {
    form.value.is_prive_vervoer = (type === 'prive')
    form.value.is_luxe_vervoer = (type === 'luxe')
  })

  watch(() => form.value.bedrijf_thema, (bedrijf_thema) => {
    const latestBerekeningVersionKevin = RIT_BEREKENING({ bedrijf_thema: 'LUCHTHAVENVERVOERKEVIN' }).find(el => el.isLatest)?.value
    const latestBerekeningVersion = RIT_BEREKENING({ bedrijf_thema }).find(el => el.isLatest)?.value
    form.value.berekening = latestBerekeningVersion || latestBerekeningVersionKevin
  })

  watch(() => form.value.is_retour, (val, oldval) => {
    if (val === oldval) return
    if (!form.value.is_retour) return
    componentData.value.adressenWijzigen = true
    if (!retourVerschillendeAdressenComputed.value) {
      setRetourAdressenFromHeen(form)
    }

    form.value.retour.datum = String(form.value.heenreis.datum)
    form.value.retour.tijd = String(form.value.heenreis.tijd)

    form.value.retour.personen = Number(form.value.heenreis.personen)
    form.value.retour.bagage = Number(form.value.heenreis.bagage)
    form.value.retour.handbagage = Number(form.value.heenreis.handbagage)
    form.value.retour.kinderstoelen = Number(form.value.heenreis.kinderstoelen)
    form.value.retour.kinderzitjes = Number(form.value.heenreis.kinderzitjes)
    form.value.retour.maxicosis = Number(form.value.heenreis.maxicosis)
  })

  const handleVerschillendeAdressen = (val, oldval) => {
    if (val === oldval) return
    if (!form.value.is_retour) return
    componentData.value.adressenWijzigen = true
    if (!val) {
      setRetourAdressenFromHeen(form)
    }
  }

  watch(() => retourVerschillendeAdressenComputed.value, handleVerschillendeAdressen)
  watch(() => componentData.value.retourVerschillendeAdressen, handleVerschillendeAdressen)

  syncPersonenBagage(form)
}
