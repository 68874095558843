<template>
  <div class="flex flex-row">
    <div>
      <select :disabled="disabled" class="block w-full" v-model="selectie">
        <option v-for="item in LUCHTHAVENS" :key="item.adres" :value="item.google">Luchthaven {{ item.name }}</option>
        <option :value="null">Zelfgekozen adres invullen</option>
      </select>

      <UIGoogleInput class="mt-1" type="text" :disabled="disabled" placeholder="Adres ..." v-if="!selectie" v-model="adres" />
    </div>

    <button v-if="adres?.place_id" :disabled="disabled" @click="adres = null" class="inline-flex justify-center items-center p-2 group">
      <i class="fas fa-times" :class="{ 'text-dashboard-dark group-hover:text-red-500': !disabled }"></i>
    </button>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits } from 'vue'
import LUCHTHAVENS from '@/constants/LUCHTHAVENS'
import UIGoogleInput from '@/components/Google/Input.vue'
import { useVModel } from '@vueuse/core'
import clone from 'just-clone'

const props = defineProps({
  disabled: Boolean,
  modelValue: [String, Object],
})

const emit = defineEmits(['update:modelValue'])

const adres = useVModel(props, 'modelValue', emit, { deep: true, passive: true })
const selectie = ref(adres.value?.place_id && LUCHTHAVENS.find(l => l.google.place_id === adres.value.place_id || l.google.adres === adres.value.adres) ? clone(adres.value) : null)

watch(selectie, (val) => adres.value = val || null)
</script>
