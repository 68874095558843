import clone from 'just-clone'

export default (formRef) => {
  if (!formRef.value.is_retour) return
  formRef.value.retour.adressen = clone(formRef.value.heenreis.adressen).reverse()
  formRef.value.retour.seconden = Number(formRef.value.heenreis.seconden)
  formRef.value.retour.kilometers = Number(formRef.value.heenreis.kilometers)
  formRef.value.retour.tolkosten = Number(formRef.value.heenreis.tolkosten)
  // formRef.value.retour.warnings = clone(formRef.value.heenreis.warnings || []).reverse()
}
