import useFindLuchthaven from '@/hooks/useFindLuchthaven'

const luchthavenReplacer = (el) => useFindLuchthaven(el)?.google || el
const ZAVENTEM = useFindLuchthaven('Zaventem')

export default (form) => {
  if (!form) return form

  form.heenreis.adressen = (form.heenreis.adressen || []).map(luchthavenReplacer)
  form.retour.adressen = !form.is_retour ? [
    ZAVENTEM.google,
    null,
  ] : (form.retour.adressen || []).map(luchthavenReplacer)

  return form
}
