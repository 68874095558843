import { isLastMinute } from '@/assets/taxiboeken-shared/dist/utils'
import { watch } from 'vue'

export default ({ componentData, form }) => {
  if (componentData.value.isNieuw && isLastMinute(form.value.heenreis.datum)) {
    form.value.is_last_minute = true
  }

  watch(() => form.value.heenreis.datum, (val, oldval) => {
    if (val === oldval) return
    form.value.is_last_minute = isLastMinute(form.value.heenreis.datum)
  })
}
