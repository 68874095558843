import { computed } from 'vue'

const isPriveVerplichtAantal = aantal => aantal % 8 === 0 || aantal % 7 === 0

export default ({ form }) => computed(() => {
  // @TODO: ts shared project: return isPriveVerplicht(form.value)

  const personen_retour = form.value.retour.personen
  const personen_heen = form.value.heenreis.personen

  if (form.value.is_prive_vervoer) return false

  if (form.value.is_retour) {
    return isPriveVerplichtAantal(personen_retour) && isPriveVerplichtAantal(personen_heen)
  }

  return isPriveVerplichtAantal(personen_heen)
})
