<template>
  <UIInput
    v-model="klant.firstname"
    label="Voornaam"
    type="text"
    class="w-full max-w-sm"
    placeholder="Voornaam ..."
  />
  <UIInput
    v-model="klant.lastname"
    label="Familienaam"
    required
    type="text"
    class="w-full max-w-sm"
    placeholder="Familienaam ..."
  />
  <UIInput
    v-model="klant.phone"
    label="Telefoonnummer"
    type="text"
    class="w-full max-w-sm"
    placeholder="Telefoonnummer ..."
    @blur="handleBlacklistCheck"
  />
  <UIInput
    v-model="klant.email"
    label="E-mailadres"
    type="text"
    class="w-full max-w-sm"
    placeholder="E-mailadres ..."
    @blur="handleBlacklistCheck"
  />
  <UISelectFixed type="LANGUAGE" class="w-full max-w-sm" v-model="klant.language" />
  <LabelBlacklisted :value="blacklisted" />
</template>

<script setup>
import { defineAsyncComponent, defineProps, ref } from 'vue'
import { useVModel, useDebounceFn } from '@vueuse/core'
import UIInput from '@/components/UI/Input/Input.vue'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'
import useApi from '@/hooks/useApi'

const LabelBlacklisted = defineAsyncComponent(() => import('@/components/UI/Labels/Blacklisted.vue'))

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
})

const api = useApi()
const emit = defineEmits(['update:modelValue'])
const klant = useVModel(props, 'modelValue', emit, { deep: true, passive: true })

const blacklisted = ref(false)
const handleBlacklistCheck = useDebounceFn(async () => {
  const { email, phone } = klant.value
  const { data } = await api.get('WHITELIST_BLACKLIST_CHECK', { email, phone })
  blacklisted.value = data.blacklisted || false
}, 900)
</script>
